<template>
  <v-container class="user-page d-flex flex-column align-stretch">
    <SimulatedDate :actual-move="actualMove" :status="status"/>
      <v-row class="align-center align-content-center justify-center" v-if="message.length != 0">
        <v-col cols="1">
          <v-avatar
                  color="orange accent-3"
                  size="40"
                  slot="icon"
          >
            <v-icon
                    color="white"
                    icon="mdi-lock"
            >
              mdi-exclamation
            </v-icon>
          </v-avatar>
        </v-col>
        <v-col cols="11">
          <v-card class="annonce">
            <v-card-text class="ml-2 text-body-1 font-weight-bold">
              {{message}}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
import SimulatedDate from "./SimulatedDate";
const urlApi = "/api/v1/";

  export default {
    name: "user",
    components: {SimulatedDate},
    data() {
      return {
        message: "",
        dialog: false,
        actualMove: {
          name: "",
          start: "",
          durationInMinutes: 0,
          end: ""
        },
        status: {
          startDate: "",
          pauseDate: "",
          started: false,
          stopped:true,
          timeInPause: 0
        },
        now: 0,
        startTimer: null,
        displayMessage: false,
        menu: false,
      }
    },

    created() {
      this.connect();
    },
    methods: {
      connect() {
        const es = new EventSource(urlApi + "flux");

        es.addEventListener('initMove', event => {
          this.actualMove = JSON.parse(event.data);
        }, false);
        es.addEventListener('initStatus', event => {
          this.status = JSON.parse(event.data);
        }, false);
        es.addEventListener('initMessage', event => {
          this.message = event.data;
        }, false);
        es.addEventListener('newMove', event => {
          this.actualMove = JSON.parse(event.data);
        }, false);
        es.addEventListener('changeStatus', event => {
          this.status = JSON.parse(event.data);
        }, false);
        es.addEventListener('newMessage', event => {
          this.message = event.data;
        }, false)
        es.addEventListener('error', () => {
          if (es.readyState > 0) {
            window.setTimeout(this.connect,2000);
          }
        }, false)
      }
    }
  }
</script>

<style scoped>
  .user-page {
    height: 100vh;
  }

  .bg-date {
    background: #26348B;
  }
  .annonce{
    background-color: #FFFFd8;
  }
</style>
